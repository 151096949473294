import { v4 as uuid } from 'uuid'

import type {
  CompetitionBaseDto,
  CompetitionDetailsDTO
} from '@elitecompetitions/client-api'

import * as FBPixel from '@utils/FBPixel'

import { CurrencyTypeEnum } from '@enums'

export const handleTrackViewContent = (
  competition: CompetitionBaseDto | CompetitionDetailsDTO
) => {
  FBPixel.track(
    FBPixel.FBPixelEventTypeEnum.VIEW_CONTENT,
    {
      content_name: competition.name,
      content_category: 'Home > Primary Carousel',
      contents: [
        {
          id: competition.id,
          quantity: 1
        }
      ],
      content_type: 'product',
      value: competition.ticketPrice,
      currency: CurrencyTypeEnum.GBP
    },
    {
      eventID: uuid()
    }
  )
}
