import Link from 'next/link'

import { useSwiper } from 'swiper/react'

import { CdnImage } from '@lib/ui'

import {
  StyledMainCarouselContent,
  StyledMainCarouselImage,
  StyledMainInfoTitle
} from '@lib/ui/MainCarousel/MainCarousel.styled'

import { getLocalAssetFromCDN } from '@helpers'

import { useJackpot } from '@hooks/competition/useJackpot'

import { handleTrackViewContent } from '../../utils/metric'
import HomeCarouselFeatureBadges from '../HomeCarouselFeatureBadges'
import HomeCarouselJackpotBadge from '../HomeCarouselJackpotBadge'
import HomeCarouselTicketPriceBadge from '../HomeCarouselTicketPriceBadge'

import { type IHomeCarouselItemProps } from './HomeCarouselItem.types'
import {
  StyledBadgeContainer,
  StyledBadgeStack,
  StyledAkaButton
} from './HomeCarouselItem.styled'

const getImagePlaceholder = (imagePlaceholder: string) => {
  return imagePlaceholder.length ? 'blur' : 'empty'
}

const HomeCarouselItem = (props: IHomeCarouselItemProps) => {
  const { competition, isNext, isPrev, isActive } = props

  const swiper = useSwiper()

  const {
    cdnImage,
    slugUrl,
    isFree = false,
    name,
    isOnSale = false,
    ticketPrice,
    saleTicketPrice,
    imagePlaceholder,
    hasJackpot = false
  } = competition

  const link = `/competitions/${slugUrl}`

  const { jackpotAmount = 0, isLoading: isLoadingJackpot = false } = useJackpot(
    {
      competition
    }
  )

  return (
    <Link href={link} passHref={true}>
      <a
        onClick={e => {
          // It's a hack cuz slideToClickedSlide (slideTo as well) doesn't work properly
          // https://github.com/nolimits4web/swiper/issues/7148
          if (isNext) {
            swiper.slideNext()
            e.preventDefault()
          } else if (isPrev) {
            swiper.slidePrev()
            e.preventDefault()
          } else if (isActive) {
            handleTrackViewContent(competition)
          }
        }}
      >
        <StyledMainCarouselImage>
          <CdnImage
            alt={name}
            image={cdnImage}
            placeholder={getImagePlaceholder(imagePlaceholder)}
            fallbackSrc={getLocalAssetFromCDN(
              '/assets/img/competition-default-image.webp'
            )}
          />

          <StyledAkaButton>Enter</StyledAkaButton>

          {hasJackpot && (
            <StyledBadgeContainer isJackpot={true}>
              <StyledBadgeStack direction="row" justifyContent="flex-start">
                <HomeCarouselJackpotBadge
                  jackpotAmount={jackpotAmount}
                  isLoadingJackpot={isLoadingJackpot}
                />
              </StyledBadgeStack>
            </StyledBadgeContainer>
          )}

          <StyledBadgeContainer>
            <StyledBadgeStack
              direction="row"
              justifyContent="flex-end"
              gap="8px"
            >
              <HomeCarouselFeatureBadges competition={competition} />

              {!isFree && (
                <HomeCarouselTicketPriceBadge
                  isOnSale={isOnSale}
                  ticketPrice={ticketPrice}
                  saleTicketPrice={saleTicketPrice}
                />
              )}
            </StyledBadgeStack>
          </StyledBadgeContainer>
        </StyledMainCarouselImage>

        <StyledMainCarouselContent>
          <StyledMainInfoTitle>{name}</StyledMainInfoTitle>
        </StyledMainCarouselContent>
      </a>
    </Link>
  )
}

export default HomeCarouselItem
