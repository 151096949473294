import { isNil } from 'lodash-es'

import { Stack, Typography } from '@mui/material'

import { Badge } from '@lib/ui'

import { numericWithComma } from '@helpers'

import { type IHomeCarouselTicketPriceBadgeProps } from './HomeCarouselTicketPriceBadge.types'
import { StyledOriginalPriceTypography } from './HomeCarouselTicketPriceBadge.styled'

const HomeCarouselTicketPriceBadge = (
  props: IHomeCarouselTicketPriceBadgeProps
) => {
  const { isOnSale = false, ticketPrice, saleTicketPrice } = props

  if (!isNil(saleTicketPrice) && isOnSale) {
    return (
      <Badge variant="price">
        <Stack direction="row" gap="4px" justifyContent="flex-start">
          <StyledOriginalPriceTypography
            fontFamily="Poppins"
            fontWeight={500}
            lineHeight={{
              lg: '36px',
              md: '30px',
              sm: '24px',
              xs: '18px'
            }}
            fontSize={{
              lg: '28px',
              md: '24px',
              sm: '20px',
              xs: '14px'
            }}
            color="var(--Neutral1)"
          >
            {`£${numericWithComma(ticketPrice)}`}
          </StyledOriginalPriceTypography>

          <Typography
            fontFamily="Poppins"
            fontWeight={700}
            lineHeight={{
              lg: '36px',
              md: '30px',
              sm: '24px',
              xs: '18px'
            }}
            fontSize={{
              lg: '28px',
              md: '24px',
              sm: '20px',
              xs: '14px'
            }}
            color="var(--Neutral1)"
          >
            {`£${numericWithComma(saleTicketPrice)}`}
          </Typography>
        </Stack>
      </Badge>
    )
  }

  return (
    <Badge variant="price">
      <Typography
        fontFamily="Poppins"
        fontWeight={500}
        lineHeight={{
          lg: '36px',
          md: '30px',
          sm: '24px',
          xs: '18px'
        }}
        fontSize={{
          lg: '28px',
          md: '24px',
          sm: '20px',
          xs: '14px'
        }}
        color="var(--Neutral1)"
      >
        {`£${numericWithComma(ticketPrice)}`}
      </Typography>
    </Badge>
  )
}

export default HomeCarouselTicketPriceBadge
